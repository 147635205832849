// Utilities
//

.opacity-25            { opacity: .25 !important; }
.opacity-50            { opacity: .5 !important; }
.opacity-75            { opacity: .75 !important; }
.opacity-90           { opacity: .9 !important; }
.opacity-100           { opacity: 1 !important; }

.text-tiny             { font-size: $tiny-font-size !important; }
.text-small            { font-size: $small-font-size !important; }
.text-big              { font-size: $big-font-size !important; }
.text-large            { font-size: $large-font-size !important; }
.text-xlarge           { font-size: $xlarge-font-size !important; }

.line-height-1         { line-height: 1 !important; }
.line-height-condenced { line-height: 1.3 !important; }
.line-height-inherit   { line-height: inherit !important; }

.font-weight-light     { font-weight: $font-weight-light !important; }
.font-weight-semibold  { font-weight: $font-weight-semibold !important; }
.font-weight-bolder    { font-weight: $font-weight-bolder !important; }
.text-decoration-none  { text-decoration: none !important; }
.text-transform-none   { text-transform: none !important; }

.overflow-hidden       { overflow: hidden !important; }
.overflow-scroll       { overflow: scroll !important; }
.overflow-auto         { overflow: auto !important; }

.box-shadow-none       { box-shadow: none !important; }

.cursor-pointer        { cursor: pointer !important; }

// *******************************************************************************
// * Width

@each $breakpoint in slice-list(map-keys($grid-breakpoints), 2) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .w#{$infix}-100 { width: 100% !important; }
    .w#{$infix}-auto { width: auto !important; }
  }
}

// *******************************************************************************
// * Negative horizontal margins

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    $rtl-prefix: if($rtl-support == true, 'html:not([dir=rtl]) body:not([dir=rtl]) ', '');

    @each $size, $length in $spacers {
      #{$rtl-prefix}.mr#{$infix}--#{$size},
      #{$rtl-prefix}.mx#{$infix}--#{$size} {
        margin-right: -$length !important;
      }
      #{$rtl-prefix}.ml#{$infix}--#{$size},
      #{$rtl-prefix}.mx#{$infix}--#{$size} {
        margin-left: -$length !important;
      }

      @if $rtl-support == true {
        .mr#{$infix}--#{$size},
        .mx#{$infix}--#{$size} {
          margin-left: -$length !important;
        }
        .ml#{$infix}--#{$size},
        .mx#{$infix}--#{$size} {
          margin-right: -$length !important;
        }
      }
    }
  }
}
