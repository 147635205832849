.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

.page-height {
  height: calc(100% - var(--toolbar-height)) !important;
}

.toolbar-height {
  height: var(--toolbar-height) !important;
}

.spinner-container {
  position: relative;
}

$positions: relative, absolute, fixed, sticky;
@each $position in $positions {
  .pos-#{$position} {
    position: $position;
  }
}

$displays: none, block, flex, inline, inline-block;
@each $display in $displays {
  .d-#{$display} {
    display: $display !important;
  }
}

$fontWeights: normal, bold, bolder, lighter;
@each $fontWeight in $fontWeights {
  .font-weight-#{$fontWeight} {
    font-weight: $fontWeight;
  }
}

$textAligns: left, center, right, justify;
@each $textAlign in $textAligns {
  .text-#{$textAlign} {
    text-align: $textAlign;
  }
}

.overflow-wrap {
  overflow-wrap: break-word;
  text-wrap: wrap;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
