@each $space in $spaces {
  .m-#{$space} {
    margin: var(--spacing-#{$space}) !important;
  }
  .ml-#{$space},
  .mx-#{$space} {
    margin-left: var(--spacing-#{$space}) !important;
  }
  .mr-#{$space},
  .mx-#{$space} {
    margin-right: var(--spacing-#{$space}) !important;
  }
  .mt-#{$space},
  .my-#{$space} {
    margin-top: var(--spacing-#{$space}) !important;
  }
  .mb-#{$space},
  .my-#{$space} {
    margin-bottom: var(--spacing-#{$space}) !important;
  }

  .p-#{$space} {
    padding: var(--spacing-#{$space}) !important;
  }
  .pl-#{$space},
  .px-#{$space} {
    padding-left: var(--spacing-#{$space}) !important;
  }
  .pr-#{$space},
  .px-#{$space} {
    padding-right: var(--spacing-#{$space}) !important;
  }
  .pt-#{$space},
  .py-#{$space} {
    padding-top: var(--spacing-#{$space}) !important;
  }
  .pb-#{$space},
  .py-#{$space} {
    padding-bottom: var(--spacing-#{$space}) !important;
  }
}

// Line Heights
$line-heights: 'sm' 1.2em, 'md' 1.5em, 'lg' 2em;
@each $space, $height in $line-heights {
  .line-height-#{$space} {
    line-height: $height !important;
  }
}
