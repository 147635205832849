
.fx-column, .fx-row, .fx-row-wrap {
  display: flex!important;
  max-width: 100%;

  .fx-grow {
    flex-grow: 1
  }

  .fx-shrink {
    flex-shrink: 1
  }

  $fxOrders: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;
  @each $order in $fxOrders {
    .fx-order-#{$order} {
      order: $order;
    }
  }

  .fx-gap-0 {
    gap: 0;
    grid-gap: 0;
  }

  &.fx-gap-xs {
    gap: $spacing-xs;
    grid-gap: $spacing-xs;
  }

  &.fx-gap-sm {
    gap: $spacing-sm;
    grid-gap: $spacing-sm;
  }
  &.fx-gap-md {
    gap: $spacing-md;
    grid-gap: $spacing-md;
  }
  &.fx-gap-lg {
    gap: $spacing-lg;
    grid-gap: $spacing-lg;
  }
  &.fx-gap-xl {
    gap: $spacing-xl;
    grid-gap: $spacing-xl;
  }

}

.fx-column {
  flex-direction: column;

  $alignments: start, center, end, space-evenly, space-between;
  @each $alignment in $alignments {
    &.y-#{$alignment} {
      justify-content: $alignment;
    }
    &.x-#{$alignment} {
      align-items: $alignment;
    }
  }
}

.fx-row {
  flex-direction: row;
}

.fx-row-wrap {
  flex-wrap: wrap;
}

.fx-row, .fx-row-wrap {
  // Default alignment for the parent container
  $alignments: start, center, end, space-evenly, space-between;
  @each $alignment in $alignments {
    &.y-#{$alignment} {
      align-items: $alignment;
    }
    &.x-#{$alignment} {
      justify-content: $alignment;
    }
  }


  // Children can self-align
  $selfAlignments: start, center, end;
  @each $alignment in $selfAlignments {
    .align-self-#{$alignment} {
      align-self: $alignment;
    }
  }
}

.fx-fill {
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
}

$fxRatios: 0, 1, 2, 3, 4;
@each $grow in $fxRatios {
  // NOTE:
  // fx-1-1 = flex-grow: 1 and flex-shrink: 1
  // fx-0-1 = flex-grow: 0 and flex-shrink: 1
  // fx-0-0 = flex-grow: 0 and flex-shrink: 0
  @each $shrink in $fxRatios {
    .fx-#{$grow}-#{$shrink},
    .fx-#{$grow}-#{$shrink}-0,
    .fx-#{$grow}-#{$shrink}-auto {
      flex-grow: $grow;
      flex-shrink: $shrink;
    }

    $flexBasisPercentages: 10, 25, 33, 50, 100;
    @each $basis in $flexBasisPercentages {
      .fx-#{$grow}-#{$shrink}-#{$basis} {
        flex: #{$grow} #{$shrink} #{$basis + '%'};
      }
    }

    .fx-#{$grow}-#{$shrink}-0 {
      flex-basis: 0; //Makes them all the same size
    }

    .fx-#{$grow}-#{$shrink}-auto {
      flex-basis: auto;
    }
  }
}
