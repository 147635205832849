@import './variables';

.fx-column,
.fx-row,
.fx-row-wrap {
  display: flex;
  max-width: 100%;
  $fxRatios: 0, 1, 2, 3, 4;
  @each $grow in $fxRatios {
    @each $shrink in $fxRatios {
      .fx-#{$grow}-#{$shrink},
      .fx-#{$grow}-#{$shrink}-0 {
        flex-grow: $grow;
        flex-shrink: $shrink;
      }

      .fx-#{$grow}-#{$shrink}-0 {
        flex-basis: 0; // Make them all the same initial size
      }
    }
  }
}
.fx-row-wrap {
  flex-wrap: wrap;
}

.fx-column {
  flex-direction: column;

  &.y-start {
    justify-content: flex-start;
  }
  &.y-center {
    justify-content: center;
  }
  &.y-end {
    justify-content: flex-end;
  }
  &.y-space-evenly {
    justify-content: space-evenly;
  }
  &.y-space-between {
    justify-content: space-between;
  }

  &.x-start {
    align-items: start;
  }
  &.x-center {
    align-items: center;
  }
  &.x-end {
    align-items: end;
  }
  &.x-space-between {
    align-items: space-between;
  }
}

.fx-row,
.fx-row-wrap {
  &.x-start {
    justify-content: flex-start;
  }
  &.x-center {
    justify-content: center;
  }
  &.x-end {
    justify-content: flex-end;
  }
  &.x-space-between {
    justify-content: space-between;
  }

  &.y-start {
    align-items: start;
  }
  &.y-center {
    align-items: center;
  }
  &.y-end {
    align-items: end;
  }
}

.fx-gap-0 {
  gap: 0 !important;
  grid-gap: 0 !important;
}
.fx-gap-xs {
  gap: $spacing-xs !important;
  grid-gap: $spacing-xs !important;
}
.fx-gap-sm {
  gap: $spacing-sm !important;
  grid-gap: $spacing-sm !important;
}
.fx-gap-md {
  gap: $spacing-md !important;
  grid-gap: $spacing-md !important;
}
.fx-gap-lg {
  gap: $spacing-lg !important;
  grid-gap: $spacing-lg !important;
}
.fx-gap-xl {
  gap: $spacing-xl !important;
  grid-gap: $spacing-xl !important;
}
.fx-grow {
  flex-grow: 1;
}

.fx-shrink {
  flex-shrink: 1;
}

@mixin grid($columns, $min-width, $margin) {
  /* max num of cols */
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max($min-width, (100% - ($columns - 1) * $margin) / $columns), 1fr)
  );
  gap: $margin;
}

$columns: 1, 2, 3, 5;
$grid-margin: $spacing-md;

/** Default Grid Size **/
.fx-grid {
  @include grid(3, 300px, $grid-margin);
}

$widths: 'xxs' 100px 10, 'xs' 170px 8, 'sm' 200px 6, 'md' 300px 4,
  'md-lg' 300px 2, 'lg' 380px 2, 'xl' 600px 2;
@each $width-name, $min-width, $default-columns in $widths {
  /** Grid Sizes: .grid-xs, .grid-sm, .grid-md, etc... **/
  .fx-grid-#{$width-name} {
    @include grid($default-columns, $min-width, $grid-margin);
  }

  /** Grid columns: .grid-xs-300, .grid-lg-500, etc. */
  @each $column-count in $columns {
    .fx-grid-#{$width-name}-#{$column-count} {
      @include grid($column-count, $min-width, $grid-margin);
    }
  }
}
