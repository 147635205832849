/* You can add global styles to this file, and also import other style files */
$fa-font-path: "../webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/brands";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/solid";

@import '@abt-desk/rsc/lib/styles';

@import './theming/theming.scss';

@import "./lcc-material.scss";
@import "./variables";
@import "./flex";
@import "./spacing.scss";
@import "./menus.scss";
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';

// NOTE: It is important that root gets imported first
@import '@re-core/ngx-ui/assets/styles/root';
@import '@re-core/ngx-ui/assets/styles/flex';
@import '@re-core/ngx-ui/assets/styles/spacing';
@import '@re-core/ngx-ui/assets/styles/layout';
@import '@re-core/ngx-ui/assets/styles/material';

// Overriding some of the core's
.mat-mdc-form-field.mat-mdc-form-field-label-always-float.mat-form-field-appearance-outline {
  margin-top: unset;
}

// NOT: Not doing colors right now as it may conflict with what is being done in theming.scss
// @import '@re-core/ngx-ui/assets/mixins/colors
// @include colors($light-theme);
.page {
  height: 100%;
  width: 100%;
}

.font-weight-500 {
  font-weight: 500;
}
.line-through {
  text-decoration-line: line-through !important;
}

.mat-body-2 {
  font-weight: 500;
}

/*#region DELETE Remove once we eliminate bootstrap themes*/
a:focus, button:focus {
  outline:none!important;
}


/*#endregion DELETE Remove once we eliminate bootstrap themes*/
.line-height-0 {
  line-height: initial;
}

img.round {
  border-radius: 50%;
}
.line-height-0 {
  line-height: initial;
}

.text-capitalize-first {
  ::first-letter {
    text-transform: capitalize;
  }
}

.text-capitalize-first::first-letter {
  text-transform: capitalize;
}

.text-smaller {
  font-size: 75%;
}

.clean-scroll-bar {
  overflow-x: hidden;
  overflow-y: auto;
  transition: color 0.3s;

  &.show-on-hover:hover::-webkit-scrollbar-thumb,
  &:not(.show-on-hover)::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px #ccc;
  }

 &::-webkit-scrollbar {
    width: 14px;
    background-color: rgba(0, 0, 0, 0.1)
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border: 2px solid transparent;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

}
/**
* CSS to add the spinner to a button that is submitting
**/
@keyframes spinner {
  to {transform: rotate(360deg);}
}

.float-container {
  position: relative;

  .float {
    position: absolute!important;

    &.top-right {
      top: 0px;
      right: 0px;
    }
  }
}

button.spinner:before,
a.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}
.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pdf-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  iframe {
    height: 100%;
    width: 100%;
  }
}

.bg-transparent {
  background-color: transparent!important;
}

.text-wrap {
  word-wrap: break-word;
  white-space: normal;
}

.page {
  padding: 2em 4em;
  height:100%;

  .page-banner {
    margin-left: -4em;
    margin-right: -4em;
    margin-top: -2em;
    position: relative;
    z-index: 1000;
  }
}

.page-padding-top {
  padding: 2em 0;
}

hr {
  min-height: 1px;
}

.image-container {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

/******
* Quill Editor
*****/
.ql-toolbar {
  padding: 0px;
  margin: 0px;
  border-bottom: 1px solid #cccccc;
  background: white;
}

.hide-toolbar {
  .ql-toolbar {
    padding: 0;
    border: none;
    display: none;
  }
}

/** END QUILL EDITOR */

.disable-overlay {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: not-allowed;
}

.banner-height {
  background-color: #f2f2f2 !important;
  color: #2B2E30;
  height:226px
}

.fixed {
 position: fixed;
}

// Disables the text-highlight: https://stackoverflow.com/a/4407335
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

a.disabled {
  pointer-events: none;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.circle {
  border-radius: 50%;
}

.circle-btn {
  width: 32px;
  height: 32px;
  object-fit: contain;
  padding: 0;
}

.clickable { cursor: pointer; }
.pointer-default { cursor:default; }

.click-disabled {
  cursor: not-allowed;
}

.close:focus {
  outline: 0;
}

.mat-select-panel {
  margin-top: 30px;
}

img.avatar {
  object-fit: cover;
  object-position: 100% 0;
}

img.badge {
  object-fit: contain;
  width: 60px;
  height: 60px;
  &.small {
    width: 34px;
    height: 34px;
  }
}

img.avatar, img.badge {
  background: white;
  border-radius: 50%;

  &.borders {
    position: relative;
    border: 2px solid #FF8800;
    outline: 1px solid #1f69ff;
  }
}

.step-sub-header {
  font-size: 34px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.25px;
}

.greetings-subheader{
  font-size: 24px;
  line-height: 32px;
  color:#727476;
}

.box-border{
  border: 1px solid #E9E9E9;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: #ffff;
  padding: 23px;
}

.description-section{
  position:relative;
  padding-left:30px;
}

.main-step-section{
  padding-left:75px;
  padding-top:35px
}

.link-color {
  color: #1f69ff;
}

.link-background {
  background-color: #e4edff;
  color: #1f69ff;
  border-radius: 4px;
  width: 14vw;
}

.grey-background {
  background: #f9f9f9;
}

.left-nav {
  width: 97%;
  margin-top:5px;
}

.package-option {
  border-radius: 8px;
  width: 100%;
}

.package-icon {
  color:#323232;
}

.medium-text-color {
  color:#2B2E30;
}

.smaller-text-color {
  color: #727476;
}

.selected-outline{
  border:1px solid;
  border-top:0px;
  border-color: #1F69FF !important;
  background-color: white;
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 24px -6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.selected-border{
  border: solid 1px #E9E9E9;
  margin-left: 8px;
  border-radius: 8px;
}

.border-bottom-none{
  border-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.border-top-none{
  border-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.margin-bottom{
  margin-bottom: 10px;
}

.top-line{
  border-top: 1px solid #727476;
}

.border-radius-4 {
  border-radius: 4px;
}

.color-black {
  color:#000000;
}

.divider-line {
  color: #E9E9E9;
}

.header-line {
  margin-left: -21px;
  margin-right: -21px;
}

.office-title {
  font-size: 10px;
  color: #727476;
}

.comments-label {
  font-size: 16px;
  color: #727476;
}

.scroll-container {
  max-height: 350px;
  overflow-y: scroll;
}

.period-control__arrow {
  color: rgba(0, 0, 0, 0.54) !important;
}

.period-selector__button--active {
  color: #1f69ff !important;
}

.period-control__button:focus:after {
  background-color: #1f69ff !important;
}

.mat-calendar-body-today {
  border: none !important;
}

.mat-icon {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}

.photo-count {
  border-radius: 24px;
  display: inline-block;
  border: none;
	height: 32px;
	background-color: #E4EDFF;
	color: #1F69FF;
}

.add-photo-icon {
  color:#1F69FF
}

lc-property-photo-upload,
lc-file-upload {
  .ngx-file-drop__drop-zone {
    border: none !important;
    height: 200px !important;
  }
}

.back-btn {
  color: #1F69FF;
  box-shadow: none !important;
}

.summary-card {
  background-color: #f9f9f9;
}
.mat-mdc-tab-link {
  font-weight: normal;
  background-color: #ffffff;
  width: 100px;
  opacity: 1;
  color: #2B2E30;
}
.mat-mdc-tab-label-active {
  color: #1f69ff;
  font-weight: 400;
  opacity: 1;
}
.cdk-drag .photo {
  cursor: grab;
}

#product-photos-drag .photo .photo-card{
  cursor: pointer;
}
.manage-product-photo.isManagePhotos .photo .photo-card {
  cursor: pointer;
}
.manage-product-photo .photo .photo-card {
  cursor: grab;
}

.form-margin {
  margin-top:40px;
  margin-bottom:40px
}

.cancel-btn {
  color: #1f69ff;
}

.cancel-btn:hover {
  background-color: #e4edff;
}

.error-font {
  font-size: 16px;
}

.form-width {
  width: 500px;
}

.desc-color {
  color: #2b2e30;
}

.addr-1 {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.addr-2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.sumry-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}


.form-top {
  margin-top: 45px;
}


.font-weight-500 {
  font-weight: 500;
}

.address-line-2 {
  font-size: 12px;
}

.footer-space {
  margin-bottom: 256px;
}

.address-line-1 {
  font-size: 16px;
}

.form-margin-top {
  margin-top:32px;
}

.title-margin-top {
  margin-top:40px;
}

.photo-placeholder {
  font-size: 16px;
  color:#2B2E30;
  line-height: 24px;
}

.editor-border {
  border: 1px solid #1f69ff;
}

@mixin page-padding($spacing-x, $spacing-y) {
  .page-header, .page-padding {
    padding: $spacing-y $spacing-x;
  }

  .page-content {
    height: 100%;
    width: 100%;
    padding: $spacing-y $spacing-x;
    background-color: #fcfcfc; // TODO: Move this to colors and base it on the palette
  }

  .page-sidenav {
    // Offset the paddings from the page
    margin-left: -($spacing-x);
    margin-top: -$spacing-y;
  }
}

.app-page {
  height: 100%;
  width: 100%;
  position: relative;
  @include page-padding(6em, 2em);
}

.gallery-background {
  background-color: #f3f2fa;
}


.dragging {
  user-select: none;
  * { user-select: none; }

  // By default, show cursor not allowed. The drop zones will override this when able to drop
  cursor: not-allowed;
  * { cursor: not-allowed; }

  // If we are dragging and within a cdkdrag container, show the grabbing cursor
  .cdk-drop-list.cdk-drop-list-dragging, .v3-scrollable-photos {
    cursor: grabbing;
    * { cursor: grabbing;  }
  }

  .mat-drawer, .mat-drawer.mat-drawer-side {
    z-index: 0;
  }
}

// quill-editor
quill-editor.ng-invalid .ql-container {
  border: 1px solid red;
}
