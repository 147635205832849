// Base
//

body {
  -moz-font-feature-settings: "liga" on;
  -webkit-font-smoothing: antialiased;
          font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}


// *******************************************************************************
// * Containers

.container,
.container-fluid {
  padding-right: $container-padding-x-sm;
  padding-left: $container-padding-x-sm;

  @include media-breakpoint-up(lg) {
    padding-right: $container-padding-x;
    padding-left: $container-padding-x;
  }
}
