

// Spaces from: https://getbootstrap.com/docs/4.0/utilities/spacing/
$spacer: 1rem !default;
$spaces: "1" .25, "2" .5, "3" 1, "4" 1.5 "5" 3;
@each $name, $multiplier in $spaces {
  $space: $spacer * $multiplier;
  .m-n#{$name} { margin: -$space !important; }
  .mt-n#{$name} { margin-top: -$space !important; }
  .mb-n#{$name} { margin-bottom: -$space !important; }
  .mb-n#{$name} { margin-bottom: -$space !important; }
  .ml-n#{$name} { margin-left: -$space!important; }
  .mr-n#{$name} { margin-right: -$space !important;  }
  .mx-n#{$name} {
    margin-left: -$space !important;
    margin-right: -$space !important;
  }
  .my-n#{$name} {
    margin-top: -$space !important;
    margin-bottom: -$space !important;
  }
}

$spaces: "xs" $spacing-xs, "sm" $spacing-sm, "md" $spacing-md, "lg" $spacing-lg, "xl" $spacing-xl;
@each $name, $space in $spaces {
  .m-#{$name} { margin: $space; }
  .mt-#{$name}, .my-#{$name}  { margin-top: $space; }
  .mb-#{$name}, .my-#{$name}  { margin-bottom: $space; }
  .ml-#{$name}, .mx-#{$name} { margin-left: $space; }
  .mr-#{$name}, .mx-#{$name} { margin-right: $space;  }

  .p-#{$name} { padding: $space; }
  .pt-#{$name}, .py-#{$name}  { padding-top: $space; }
  .pb-#{$name}, .py-#{$name}  { padding-bottom: $space; }
  .pl-#{$name}, .px-#{$name} { padding-left: $space; }
  .pr-#{$name}, .px-#{$name} { padding-right: $space;  }

}
