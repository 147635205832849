// Layouts
//

.layout-wrapper,
.layout-inner {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  width: 100%;
}

.layout-wrapper {
  overflow: hidden;
}

.layout-inner {
  min-height: 100vh;
}

.layout-container,
.layout-content,
.layout-content > * {
  min-height: 1px;
}

.layout-container {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  padding: 0;

  .layout-without-sidenav & {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.layout-content {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
}

// *******************************************************************************
// * Layout 1

.layout-1 {
  .layout-inner {
    flex-direction: column;
  }

  .layout-content {
    flex-basis: 100%;
    width: 0;
    min-width: 0;
    max-width: 100%;
  }
}

// Reset paddings
html:not(.layout-navbar-fixed):not(.layout-fixed):not(.layout-fixed-offcanvas) .layout-container {
  padding-top: 0 !important;
}
html:not(.layout-footer-fixed) .layout-content {
  padding-bottom: 0 !important;
}
@media (max-width: (map-get($grid-breakpoints, lg) - 1)) {
  html:not(.layout-navbar-fixed) .layout-1 .layout-container {
    padding-top: 0 !important;
  }
}

// Disable transitions/animations in IE 10-11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sidenav,
  .layout-container {
    transition: none !important;
    transition-duration: 0s !important;
  }
}

@include keyframes(layoutSidenavAnimation) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: $modal-backdrop-opacity;
  }
}
