// Mixins
//

// *******************************************************************************
// * Keyframes

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

// *******************************************************************************
// * Navs

@mixin appwork-nav-size($padding-y, $padding-x, $font-size, $line-height) {
   > .nav .nav-link,
  &.nav .nav-link {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height;
  }
}

@mixin appwork-nav-variant($parent, $background, $color: null) {
  $pills-selector: if($parent == '', '.nav-pills', '#{$parent}.nav-pills, #{$parent} > .nav-pills');

  #{$pills-selector} .nav-link.active {
    @include plain-hover-focus {
      background-color: $background;
      color: if($color, $color, yiq($background));
    }
  }
}

@mixin appwork-nav-theme($background, $color: null) {
  @include appwork-nav-variant('', $background, $color);
}
