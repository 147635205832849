// scss variables
$spaces: '0', 'xs', 'sm', 'md', 'lg', 'xl';

:root {
  // Used for spacing (see _spacing.scss)
  --spacing-0: 0;
  --spacing-xs: 5px;
  --spacing-sm: 10px;
  --spacing-md: 15px;
  --spacing-lg: 20px;
  --spacing-xl: 25px;
  --toolbar-height: 64px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg-app: #424242;
  }
}
