.mat-mdc-form-field {
  width: 100%;

  /**
  * Fields that have hints that always display, should allocate
  * some space for the hint, otherwise it gets too close to the fields below
  */
  &.with-hint .mat-mdc-form-field-subscript-wrapper {
    margin-bottom: var(--spacing-sm);
  }

  /**
  * The label at the top, when floating, should have some margin, 
  * otherwise it gets too close to other fields above
  */
  &.mat-mdc-form-field-label-always-float.mat-form-field-appearance-outline {
    margin-top: var(--spacing-xs);
  }
}
