/**
* Standard Imports
*/
@use 'sass:map';
@use '@angular/material' as mat;

@mixin background-color($pallete, $level: 500, $opacity: null) {
  background-color:  mat.m2-get-color-from-palette($pallete, $level, $opacity)!important;
  color:  mat.m2-get-color-from-palette($pallete, $level + '-contrast')!important;

  &.faded {
    // Allow a faded background color
    background-color:  mat.m2-get-color-from-palette($pallete, $level, .5)!important;
  }
}

@mixin border-color($pallete, $level: 500, $opacity: null, $side: null) {
  border-color: mat.m2-get-color-from-palette($pallete, $level, $opacity) !important;
  @if $side {
    border-#{$side}: 1px solid;
  } @else {
    border: 1px solid;
  }
}

@mixin colors($theme) {
  // Get the palettes we will use the colors for
  $color-config: mat.m2-get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $accent:  map.get($color-config, 'accent');
  $warn:    map.get($color-config, 'warn');
  $background: map.get($color-config, background);
  $foreground: map.get($color-config, foreground);

  $primary-color:  mat.m2-get-color-from-palette($primary);
  $border-color: mat.m2-get-color-from-palette($foreground, divider);

  .bg-hovered:hover {
    background-color: mat.m2-get-color-from-palette($background, focused-button);
  }

  .primary-color {
    color: $primary-color !important;

    // Hack: This is needed because we built our own icons and they have different fill settings on each element
    mat-icon svg g {
      fill: $primary-color !important;
    }
  }

  // Enumerate all the palettes
  $palettes: "warn" $warn, "primary" $primary, "accent" $accent;
  @each $name, $palette in $palettes {
    // Create bg colors
    .bg-#{$name} { @include background-color($palette); }
    .bg-#{$name}-100 { @include background-color($palette, 100); }
    .bg-#{$name}-200 { @include background-color($palette, 200); }
    .bg-#{$name}-500 { @include background-color($palette, 500); }
    .bg-#{$name}-900 { @include background-color($palette, 900); }

    .bg-#{$name}-100-faded { @include background-color($palette, 100, .5); }
    .bg-#{$name}-faded { @include background-color($palette, 500, .5); }

    // Create Text Styles for each palette (i.e. - text-primary, text-accent, text-warn)
    .text-#{$name} { color: mat.m2-get-color-from-palette($palette, 500); }
    .text-#{$name}-500 { color: mat.m2-get-color-from-palette($palette, 500); }
    .text-#{$name}-900 { color: mat.m2-get-color-from-palette($palette, 900); }

    .b-#{$name} { border-color: mat.m2-get-color-from-palette($palette, 500); }


  }

  .default-bg-and-border { background-color: mat.m2-get-color-from-palette($foreground, divider); }

  .bg-transparent { background-color: transparent; }
  .bg-card { @include background-color($background, card);}

  .bg-page { background-color: mat.m2-get-color-from-palette($background, page); }
  .bg-hover:hover { background-color: mat.m2-get-color-from-palette($background, background)}
  .mat-mdc-card {background-color: mat.m2-get-color-from-palette($background, background, .6); }
  .bg-card-faded { @include background-color($background, card, .5);}
  .bg-faded { background-color: mat.m2-get-color-from-palette($background, background, .6); }
  .bg-light-contrast { background-color: mat.m2-get-color-from-palette($foreground, text, .1); }
  .bg-lightest { background-color: mat.m2-get-color-from-palette($foreground, text, .05); }
  .bg-light-card { background-color: mat.m2-get-color-from-palette($foreground, text, .1); }
  .bg-unset { background-color: unset!important; }

  .text-default { color: mat.m2-get-color-from-palette($foreground, text); }
  .text-faded-75 { color: mat.m2-get-color-from-palette($foreground, text, .75); }
  .text-faded-90 { color: mat.m2-get-color-from-palette($foreground, text, .90); }
  .text-faded,
  a.disabled:not(.mat-button) { // This is for disabled links that are not buttons
    color: mat.m2-get-color-from-palette($foreground, text, .6);

    &:disabled {
      color: mat.m2-get-color-from-palette($foreground, text, .3);
    }
  }
  .status-container.selected {
    .status-bar {
      border-color: mat.m2-get-color-from-palette($foreground, text, .4)!important;
    }
  }

  /** This is to override the rsc package that sets the mat-primary color to purple... */
  .mat-icon.mat-primary {
    color: var(--mat-icon-color)!important;
  }

  .mat-mdc-form-field-required-marker {
    color: mat.m2-get-color-from-palette($warn)!important;
  }

  .mat-mdc-outlined-button.mat-primary:not(:disabled) {
    border-color: mat.m2-get-color-from-palette($primary, 500);
  }

  lc-editor {
    &.cdk-focused:not(.ng-invalid) {
      .ql-toolbar, .ql-container {
        border-color: mat.m2-get-color-from-palette($primary);
      }
    }

    &.ng-invalid {
      .ql-toolbar, .ql-container {
        border-color: mat.m2-get-color-from-palette($warn);
      }
    }
   }



  /**
  * Borders
  */

  $border-width: 1px;

  // No Borders...
  .border-0        { border: 0 !important; }
  .border-top-0    { border-top: 0 !important; }
  .border-right-0  { border-right: 0 !important; }
  .border-bottom-0 { border-bottom: 0 !important; }
  .border-left-0   { border-left: 0 !important; }

  // Default borders
  .border         { border: $border-width solid $border-color !important; }
  .border-top, .border-y     { border-top: $border-width solid $border-color !important; }
  .border-right   { border-right: $border-width solid $border-color !important; }
  .border-bottom, .border-y  { border-bottom: $border-width solid $border-color !important; }
  .border-left    { border-left: $border-width solid $border-color !important; }

  // Borders for thickness
  $widths: "1" 1px, "2" 2px, "3" 3px, "4" 4px, "5" 5px;
  @each $width, $width-pixels in $widths {
    .border-#{$width}         { border: $width-pixels solid $border-color !important; }
    .border-top-#{$width}     { border-top: $width-pixels solid $border-color !important; }
    .border-right-#{$width}   { border-right: $width-pixels solid $border-color !important; }
    .border-bottom-#{$width}  { border-bottom: $width-pixels solid $border-color !important; }
    .border-left-#{$width}    { border-left: $width-pixels solid $border-color !important; }

    .border-#{$width}-trans         { border: $width-pixels solid transparent !important; }
  }

  // Borders for each color palette
  $palettes: 'default' $foreground, "warn" $warn, "primary" $primary, "accent" $accent;
  @each $name, $palette in $palettes {
    $color:  mat.m2-get-color-from-palette($palette);
    @if $name == 'default' {
      $color: mat.m2-get-color-from-palette($palette, divider);
    }
    .border-#{$name} { @include border-color($palette, 500); }
    .border-color-#{$name} { border-color: $color !important; }
  }

  .default-bg-and-border {
    border-radius: 10px;
    $border-color: mat.m2-get-color-from-palette($foreground, divider) ;
    border: 1px solid $border-color !important;
  }

  .border-light { border-color: $border-color!important }
  .border-round { border-radius: 10px }
  .border-dashed { border-style: dashed!important; }
  .border-dotted { border-style: dotted!important; }

  /**
  * OTHERS
  */

  // Get the individual color palettes
  .mat-toolbar-row:not([class*="background"]) {
    // app-bar is too dark. Use the same color as the card: https://github.com/angular/components/blob/master/src/material/sidenav/_sidenav-theme.scss
    background-color: mat.m2-get-color-from-palette($background, card);
  }

  .mat-mdc-card { background-color: mat.m2-get-color-from-palette($background, card); }

  /********************************************************************
  * Custom Bootstrap overrides
  * NOTE: These are only needed while we are still supporting bootstrap
  *******************************************************************/
  a.mat-mdc-tab-link:hover {
    // NOTE: This style is only needed while we are using bootstrap. Once bootstrpa is removed, we can remove this style
    color: mat.m2-get-color-from-palette($primary, 500);
  }

  .form-control {
    // Supporting the old bootstrap for the credit card input
    color: mat.m2-get-color-from-palette($foreground, text);
    border-color: mat.m2-get-color-from-palette($foreground, text, .5);
    background-color: mat.m2-get-color-from-palette($background, background, .5);
  }
}
