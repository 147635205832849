$ui-icon-size: 46px !default;
$ui-icon-font-size: 18px !default;
$ui-icon-size-sm: 30px !default;
$ui-icon-font-size-sm: 14px !default;
$ui-icon-border-width: 2px !default;

// *******************************************************************************
// * Utilities

.ui-square,
.ui-rect,
.ui-rect-30,
.ui-rect-60,
.ui-rect-67,
.ui-rect-75 {
  position: relative !important;
  display: block !important;
  padding-top: 100% !important;
  width: 100% !important;
}

.ui-rect {
  padding-top: 50% !important;
}

.ui-rect-30 {
  padding-top: 30% !important;
}

.ui-rect-60 {
  padding-top: 60% !important;
}

.ui-rect-67 {
  padding-top: 67% !important;
}

.ui-rect-75 {
  padding-top: 75% !important;
}

.ui-rect-content {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

// *******************************************************************************
// * Icons

.ui-icon {
  display: inline-block;
  width: $ui-icon-size;
  height: $ui-icon-size;
  border: $ui-icon-border-width solid;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  font-size: $ui-icon-font-size;
  line-height: calc(#{$ui-icon-size} - #{$ui-icon-border-width * 2});

  &.border-0 {
    line-height: $ui-icon-size;
  }

  &.border-center {
    line-height: calc(#{$ui-icon-size} - #{$ui-icon-border-width * 2} - 2px);
  }
}

.ui-icon-sm {
  width: $ui-icon-size-sm;
  height: $ui-icon-size-sm;
  font-size: $ui-icon-font-size-sm;
  line-height: calc(#{$ui-icon-size-sm} - #{$ui-icon-border-width * 2});

  &.border-0 {
    line-height: $ui-icon-size-sm;
  }
}

// *******************************************************************************
// * Background

.ui-bg-cover {
  background-color: rgba(0, 0, 0, 0);
  background-position: center center;
  background-size: cover;
}

// *******************************************************************************
// * Styles

.default-style {
  @import "../_appwork/include";

  $ui-icon-border-color: $gray-100;

  .ui-icon {
    border-color: $ui-icon-border-color;
  }

  .ui-bordered {
    border: 1px solid $border-color;
  }
}
